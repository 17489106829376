/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from "redux";

import type { HeaderNavState } from "../types/headerNav";

const initialState: HeaderNavState = {
  isFetching: false,
  menuItems: [],
  error: {},
};

export const headerNav = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "GET_MENU_ITEMS":
      return {
        ...state,
        isFetching: true,
      };
    case "SUCCESS_MENU_ITEMS":
      return {
        ...state,
        menuItems: action.payload,
        isFetching: false,
      };
    case "FAILURE_MENU_ITEMS":
      return {
        ...state,
        menuItems: {},
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
