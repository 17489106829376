/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from "redux";

const initialState = {
  campaignItem: {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    gclid: "",
    fbclid: ""
  },
  fetching: false
};

export const campaignInfo = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "GET_CAMPAIGN_INFO":
      return {
        ...state,
        isFetching: true,
      };
    case "SUCCESS_CAMPAIGN_INFO":
      return {
        ...state,
        campaignItem: action.payload,
        isFetching: false,
      };
    case "FAILURE_CAMPAIGN_INFO":
      return {
        ...state,
        campaignItem: {},
        isFetching: false
      };
    default:
      return state;
  }
};