export let store;
export let persistor;

import prodStore, { persistor as prodPersistor } from "./configureStore.prod";
import devStore, { persistor as devPersistor } from "./configureStore.dev";
import { useDispatch } from "react-redux";

if (process.env.NODE_ENV === "production") {
  store = prodStore;
  persistor = prodPersistor;
} else {
  store = devStore;
  persistor = devPersistor;
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
