import produce from "immer";

const initialState = {
  isLoading: false,
  data: {},
  errors: {},
};

const dashboard = produce((draft, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_START":
      draft.isLoading = true;
      break;
    case "GET_DASHBOARD_SUCCESS":
      draft.isLoading = false;
      draft.data = action.payload;
      break;
    case "GET_DASHBOARD_ERROR":
      draft.errors = action.payload;
      break;
  }
}, initialState);

export default dashboard;
