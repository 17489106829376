import * as React from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const REDIRECT_OPTS = {
  appState: { targetUrl: window.location.pathname },
  redirectUri: window.location.origin,
};

const NonexistentUser = (): JSX.Element => {
  // Correct use of auth0 - logging the user in.
  const email = localStorage.getItem("bare_auth_admin_sudo");
  const history = useHistory();

  const handleOnTryAgain = () => {
    history.push("/auth/logout");
  };

  return (
    <Container className="mt-5">
      <Row>
        <p>
          User with the email <strong>&quot;{email}&quot;</strong> doesn&apos;t
          exist in our systems, and so can&apos;t be impersonated.
        </p>
      </Row>
      <Row>
        <Button onClick={handleOnTryAgain}>Logout and try again</Button>
      </Row>
    </Container>
  );
};

export default NonexistentUser;
