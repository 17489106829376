import * as React from "react";
import { useState, SyntheticEvent } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Collapse from "react-bootstrap/Collapse";
import SVG from "react-inlinesvg";
import PlusIcon from "/app/assets/images/global/react/Plus.svg";

import MinusIcon from "/app/assets/images/global/react/minus-icon.svg";

type Props = {
  faqContent: {
    question: string;
    answer: string;
    url?: string;
  }[];
};

export const FAQs = (props: Props): JSX.Element => {
  const [openAnswer, setOpenAnswer] = useState<string>("");

  const buttonHandler = (e: SyntheticEvent<HTMLButtonElement>) => {
    const targetName = e.currentTarget.name;
    if (targetName === openAnswer) {
      setOpenAnswer("");
    } else {
      setOpenAnswer(targetName);
    }
  };

  const Plus = () => (
    <>
      <div>
        <SVG fill="currentColor" src={PlusIcon} />
      </div>
    </>
  );
  const Minus = () => (
    <>
      <div>
        <SVG fill="currentColor" src={MinusIcon} />
      </div>
    </>
  );

  const listItems = props.faqContent.map((item, idx) => (
    <div key={idx}>
      <button
        type="button"
        style={{ width: "100%" }}
        onClick={buttonHandler}
        name={item.question}
      >
        <ListGroup.Item as={"li"} key={idx.toString()}>
          <div> {item.question}</div>
          {openAnswer === item.question ? <Minus /> : <Plus />}
        </ListGroup.Item>
      </button>
      <Collapse in={openAnswer === item.question ? true : false}>
        <div className="faq-list_answer">
          <span>
            {item.answer}{" "}
            {item.url && (
              <a
                className="teal"
                href={item.url}
                rel="noreferrer"
                target="_blank"
              >
                {item.url}
              </a>
            )}
          </span>
        </div>
      </Collapse>
    </div>
  ));

  return (
    <ListGroup as={"ul"} variant="faq-list">
      {listItems}
    </ListGroup>
  );
};
