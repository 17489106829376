/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from "redux";

const initialState = {
  ip: "",
  fetching: false
};

export const ipAddress = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "GET_IP_ADDRESS":
      return {
        ...state,
        isFetching: true,
      };
    case "SUCCESS_IP_ADDRESS":
      return {
        ...state,
        ip: action.payload,
        isFetching: false,
      };
    case "FAILURE_IP_ADDRESS":
      return {
        ...state,
        ip: 'Cannot get IP Address',
        isFetching: false
      };
    default:
      return state;
  }
};