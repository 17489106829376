import * as React from "react";
import { useState, useEffect } from "react";
import SVG from "react-inlinesvg";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import ErrorIcon from "/app/assets/images/global/react/error-icon.svg";
import { ScrollToTop } from "../helpers/scrollToTop";
import { useHistory } from "react-router";

interface Props {
  content: string;
  title: string;
  error?: boolean;
  tryAgain?: boolean;
  linkBack?: () => void;
}

export const ErrorModal = (props: Props): JSX.Element => {
  const { content, title, tryAgain } = props;
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    if (tryAgain) {
      setShow(false);
      props.linkBack ? props.linkBack() : history.go(0);
    } else {
      props.linkBack ? props.linkBack() : false;
      setShow(false);
    }
  };

  useEffect(() => {
    ScrollToTop();
    if (content) {
      setShow(true);
    }
  }, [content, props.show]);

  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <span className="error-modal__title">
            <SVG width={35} height={35} fill="#F75B24" src={ErrorIcon} />
            <Modal.Title>{title}</Modal.Title>
          </span>

          <p className="error-modal__text">{content}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="black "
            onClick={handleClose}
          >
            Close
          </Button>
          <Button variant="primary" className="teal " onClick={handleClose}>
            Try Again
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
