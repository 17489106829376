import NonexistentUser from "@/applications/root/components/pages/NonexistentUser";
import { useAuth0 } from "@auth0/auth0-react";
import getCSRFToken from "@common/helpers/getCSRFToken";
import axios from "axios";
import * as React from "react";
import { FC, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { LoadingIndicator } from "./components";
import useAuthWrapped from "./hooks/useAuthWrapped";
import useIsAuthed from "./hooks/useIsAuthed";
import { AuthWrapper } from "./layouts/AuthWrapper";

const WillsRouter = React.lazy(() => import("@wills/router"));
const RootRouter = React.lazy(() => import("@/applications/root/router"));

const setAxiosHeader = (token) => {
  if (process.env.NODE_ENV === "development") {
    console.log("DECODED JWT TOKEN FROM OAUTH:");
    console.log(window.parseJwt(token));
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const AppRouter: FC = () => {
  const auth = useAuthWrapped();
  const auth0 = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const [isAuthed, isAdmin, isCookieAuth] = useIsAuthed();

  useEffect(() => {
    async function checkAuthentication() {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = getCSRFToken();

      // Do not remove if in the middle of login redirect
      if (
        location.search.includes("code=") &&
        location.search.includes("state=")
      ) {
        return null;
      }

      // Set the axios default auth token if there is one to be found.
      // This means that elsewhere in the application there is no
      // need to set it again and again. The default is cleared
      // when the user logs out.
      if (isAuthed) {
        let token;
        const sudoEmail = window.localStorage.getItem("bare_auth_admin_sudo");
        // Check to see if we're an admin?
        if (isAdmin && sudoEmail) {
          token = await auth0.getAccessTokenSilently({
            on_behalf_of: window.localStorage.getItem("bare_auth_admin_sudo"),
          });
        } else {
          if (!isCookieAuth) {
            token = await auth0.getAccessTokenSilently();
          }
        }
        if (!isCookieAuth) {
          setAxiosHeader(token);
        }
      }
    }
    if (process.env.NODE_ENV !== "development") {
      import("@sentry/react").then((Sentry) => {
        import("@sentry/tracing").then((Tracing) => {
          const config = {
            dsn: window.bareConfig.sentryDsn,

            integrations: [new Tracing.Integrations.BrowserTracing()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            // release: __GIT_HASH__,
            environment: process.env.NODE_ENV,
            initialScope: {},
          };

          if (isAuthed) {
            config.initialScope = {
              user: {
                email: auth.user.email,
                firstName: auth.user.given_name,
                lastName: auth.user.family_name,
                isSudo:
                  window.localStorage.getItem("bare_auth_admin_sudo") !== null,
              },
            };
          }

          Sentry.init(config);
        });
      });
    }

    checkAuthentication();
  }, [location, isAuthed, isAdmin, auth0, isCookieAuth]);

  // useEffect(() => {
  //   const checkForUserExistence = async () => {
  //     if (isAuthed) {
  //       try {
  //         await axios.get("/dashboard.json");
  //       } catch (e) {
  //         if (
  //           e.response.data.errors[0] === "Not Authenticated" &&
  //           window.location.pathname !== "/auth/nonexistent-user" &&
  //           isAdmin
  //         ) {
  //           history.push("/auth/nonexistent-user");
  //         }
  //       }
  //     }
  //   };
  //   checkForUserExistence();
  // }, [isAuthed]);

  if (auth0.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <AuthWrapper>
      <React.Suspense fallback={<LoadingIndicator cover={true} />}>
        <Switch>

          <Route path="/wills">
            <WillsRouter />
          </Route>

          <Route path="/internal" exact>
            <RootRouter />
          </Route>

          {/* <Route path="/auth/bare-admin" component={AdminLogin} exact /> */}
          <Route
            path="/auth/nonexistent-user"
            component={NonexistentUser}
            exact
          />

          <Route path="/" exact>
            <Redirect to="/" />
          </Route>

          <Route path="/">
            <RootRouter />
          </Route>
        </Switch>
      </React.Suspense>
    </AuthWrapper>
  );
};
