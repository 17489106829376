/* eslint no-console: "off" */

import hasAuthStore from "../react/common/helpers/hasAuthStore";
// init sentry.
import { Auth0Provider } from "@auth0/auth0-react";
import { LoadingIndicator } from "../react/common/components";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import { AppRouter } from "../react/common/router";

import { persistor, store } from "../react/common/store/configureStore";

import "bootstrap-icons/font/bootstrap-icons.scss";

import axios from "axios";

window.parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

class LS {
  static get(key: string): string {
    return window.localStorage.getItem(key);
  }
  static set(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }
  static remove(key: string): void {
    window.localStorage.removeItem(key);
  }
  static get clientId(): string {
    return LS.get("auth_client_id");
  }
  static set clientId(value) {
    LS.set("auth_client_id", value);
  }

  static get admin(): boolean {
    return !!LS.get("bare_auth_admin");
  }
  static set admin(value) {
    LS.set("bare_auth_admin", value.toString());
  }
  static get sudo(): string {
    return LS.get("bare_auth_admin_sudo");
  }
}

document.addEventListener("DOMContentLoaded", async () => {
  // are we on the admin login page?
  let clientId =
    window.location.pathname === "/auth/bare-admin"
      ? window.bareConfig.auth0AdminReactClientId
      : window.bareConfig.auth0ReactClientId;

  if (location.pathname === "/auth/bare-admin") {
    LS.admin = true;
  }

  const onRedirectCallback = (appState: { targetUrl: string }) => {
    // On Admin login redirect to home page
    if (appState && appState.targetUrl == "/auth/bare-admin") {
      window.location.href = "/prepaid/quote?admin=true";
    } else {
      window.location.href =
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname;
    }
  };

  // Are we in the middle of a login?
  if (location.search.includes("code=") && location.search.includes("state=")) {
    if (LS.clientId) {
      clientId = LS.clientId;
      LS.remove("auth_client_id");
    }
  } else {
    LS.set("auth_client_id", window.bareConfig.auth0ReactClientId);
  }

  if (location.pathname === "/auth/bare-admin" || LS.admin) {
    LS.clientId = window.bareConfig.auth0AdminReactClientId;
  } else {
    LS.clientId = window.bareConfig.auth0ReactClientId;
  }

  // final check
  if (
    window.localStorage.getItem("bare_auth_admin") &&
    location.pathname !== "/auth/bare-admin" &&
    !(location.search.includes("code=") && location.search.includes("state="))
  ) {
    if (!hasAuthStore()) {
      LS.remove("bare_auth_admin");
      LS.clientId = window.bareConfig.auth0ReactClientId;
    }
  }

  if (LS.admin) {
    clientId = window.bareConfig.auth0AdminReactClientId;
  }

  const getAppState = () => {
    if (LS.get("bare_auth_admin_sudo")) {
      return {
        on_behalf_of: LS.get("bare_auth_admin_sudo"),
      };
    } else {
      return {};
    }
  };

  if (process.env.NODE_ENV === "development") {
    console.log("Admin Client ID:");
    console.log(window.bareConfig.auth0AdminReactClientId);

    console.log("Client ID:");
    console.log(window.bareConfig.auth0ReactClientId);

    console.log("Current Client ID:");
    console.log(clientId);
  }

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
    window.axios = axios;
  }

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <PersistGate
          loading={<LoadingIndicator cover={true} />}
          persistor={persistor}
        >
          <Auth0Provider
            appState={JSON.stringify(getAppState())}
            domain={window.bareConfig.auth0ReactDomain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={window.bareConfig.auth0Audience}
            cacheLocation="localstorage"
          >
            <AppRouter />
          </Auth0Provider>
        </PersistGate>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
});
