import * as React from "react";
import { FC, useEffect } from "react";

import Loading from "/app/assets/images/global/loading.svg";

type Props = {
  cover?: boolean;
};

export const LoadingIndicator: FC<Props> = (props): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-loader show cover" id="loader">
      <div className="page-loader__svg-wrapper">
        <img src={Loading} />
        <div className="page-loader-inner"></div>
      </div>
    </div>
  );
};
