/**
 * Converts camelCase to snake_case
 *
 * @param string
 * @returns  string
 */

export const getCookie = (name: string): string => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
};
