import Cookies from "js-cookie";
import useAuthWrapped from "./useAuthWrapped";

/**
 * React hook to check if the logged in user is an admin
 * @returns true if there is an admin logged in else false
 */
const useIsAdmin = (): boolean => {
  const auth = useAuthWrapped();
  const user = auth.user;
  return user !== undefined && Cookies.get("bare_admin") === "true";
};

export default useIsAdmin;
