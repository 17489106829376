import produce from "immer";

const initialState = {
  shortname: "",
  name: "",
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case "SET_CHARITY_SHORTCODE":
      draft.shortname = payload;
      break;
    case "SET_CHARITY": {
      draft.name = payload.name;
      draft.shortname = payload.shortname;
      break;
    }
    case "RESET_CHARITY":
      return initialState;
  }
}, initialState);

export default reducer;
