import * as React from "react";
import { FC, useState } from "react";

import { filterMenuItem } from "../../types/headerNav";

export interface NavigationProps {
  data?: filterMenuItem[];
  variant?: "header" | "footer";
}

export const Navigation: FC<NavigationProps> = (props): JSX.Element | null => {
  const [state, setState] = useState<boolean>(false);
  const [active, setActive] = useState<string>(" ");

  const handleSubMenuClick = (e) => {
    setState((prevState) => !prevState);
    setActive(e.target.innerText);
  };

  const { data, variant } = props;

  const renderLink = (item: filterMenuItem) => {
    const { post_title } = item;
    return <a href={item.url}>{post_title}</a>;
  };

  const renderItems = (items: filterMenuItem[]) => {
    return items.map((item: filterMenuItem) => {
      const { ID, children, post_title } = item;
      if (variant === "header") {
        if (!children || children.length === 0) {
          return (
            <li
              key={ID}
              className={children ? "menu-item-has-children" : "none"}
            >
              {renderLink(item)}
            </li>
          );
        } else {
          return (
            <li
              key={ID}
              id={ID}
              onClick={handleSubMenuClick}
              className={
                children && active === post_title
                  ? "menu-item-has-children current-menu-parent"
                  : "menu-item-has-children "
              }
            >
              <a>{post_title}</a>
              {state ? (
                <ul className="sub-menu hide-sub-menu">
                  {renderItems(children)}
                </ul>
              ) : (
                <></>
              )}
            </li>
          );
        }
      } else if (variant === "footer") {
        return (
          <div key={ID} className="footer__content-item-text link-item">
            {renderLink(item)}
          </div>
        );
      }
    });
  };

  if (!data) {
    return null;
  }

  if (variant === "header") {
    return (
      <div className="header-site-navigation">
        <ul className="menu">{renderItems(data)}</ul>
      </div>
    );
  } else if (variant === "footer") {
    return <div>{renderItems(data)}</div>;
  }
};
