import { combineReducers } from "redux";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import auth from "./auth";
import { headerNav } from "./headerNav";
import { ipAddress } from "./ipAddress";
import { campaignInfo } from "./campaignInfo";

import dashboard from "@/applications/user-account/reducers/dashboard";
import { wg } from "@/applications/wills-generator/reducers";

const rootReducer = combineReducers({
  ipAddress,
  headerNav,
  campaignInfo,
  dashboard,
  wg,
  auth: persistReducer({ key: "auth", storage: storageSession }, auth),
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
