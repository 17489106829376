import produce from "immer";
import { get, set } from "lodash";
import { initialState } from "./initialState";

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case "SET_LOADING": {
      draft.loading = payload;
      break;
    }
    case "RETRIEVE_WILL":
    case "SET_WILL":
      // Iterate payload keys and update draft.
      draft.id = payload.id;
      draft.type = payload.type;
      draft.navigation = payload.attributes.data.navigation;
      Object.keys(payload.attributes.data.answers).forEach((step) => {
        draft.answers[step] = payload.attributes.data.answers[step];
      });
      // Object.entries(payload).forEach(([k, v]) => {
      //   draft[k] = v;
      // });
      break;
    case "UPDATE_WILL": {
      const { path, key, value } = payload;
      set(get(draft, path), key, value);
      break;
    }
    case "APPEND_TO_LIST": {
      const { path, key, value } = payload;
      get(draft, `${path}.${key}`).push(value);
      break;
    }
    case "REPLACE_IN_LIST": {
      const { path, key, value } = payload;

      const index = get(draft, `${path}.${key}`).findIndex(
        (x) => x.id === value.id
      );
      if (index >= 0) {
        set(get(draft, `${path}.${key}`), index, value);
      }
      break;
    }
    case "UPSERT_TO_ARRAY": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;
      const index = get(draft, `${path}.${key}`).findIndex(
        (x) => x[selector] === data[selector]
      );
      if (index >= 0) {
        set(get(draft, `${path}.${key}`), index, data);
      } else {
        get(draft, `${path}.${key}`).push(data);
      }
      break;
    }
    case "MERGE_DIVISION_AMTS": {
      const { path, key, value } = payload;
      get(draft, `${path}.${key}`).forEach((item, index) => {
        if (value !== undefined && typeof value[index] !== "undefined") {
          set(draft, `${path}.${key}[${index}].amount`, value[index].amount);
        }
      });
      break;
    }
    case "MERGE_ARRAYS": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;

      data.forEach((item) => {
        const index = get(draft, `${path}.${key}`).findIndex(
          (x) => x[selector] === item[selector]
        );
        if (index >= 0) {
          set(get(draft, `${path}.${key}`), index, item);
        } else {
          get(draft, `${path}.${key}`).push(item);
        }
      });

      break;
    }
    case "FIND_AND_REPLACE_IN_LIST": {
      const { path, key, value } = payload;
      get(draft, `${path}.${key}`)[value.index] = value.data;
      break;
    }

    case "REMOVE_FROM_LIST": {
      const { path, key, value } = payload;
      get(draft, `${path}.${key}`).splice(value, 1);
      break;
    }

    case "FILTER_FROM_LIST": {
      const { path, key, value } = payload;
      set(get(draft, path), key, get(draft, `${path}.${key}`).filter(value));
      break;
    }

    case "ADD_INSTANCE_TO_LIST": {
      const { path, key, value } = payload;
      get(draft, `answers.${path}`)[key].push(value);
      break;
    }
    case "REMOVE_INSTANCE_FROM_LIST": {
      const { path, key, id } = payload;
      const index = get(draft, `answers.${path}.${key}`).findIndex(
        (v) => v.id === id
      );
      get(draft, `answers.${path}.${key}`).splice(index, 1);
      break;
    }
    case "PUSH_NAV": {
      if (draft.navigation[draft.navigation.length - 1] !== payload) {
        draft.navigation.push(payload);
      }
      break;
    }
    case "POP_NAV": {
      draft.navigation.pop();
      break;
    }
    case "SLICE_NAV": {
      const firstIndex = draft.navigation.findIndex(
        (x) => x.indexOf(payload.href) !== -1
      );

      draft.navigation.length = firstIndex;
      // find the first index of the given href
      break;
    }
    case "RESET_WILL":
      return initialState;
    case "FILTER_FROM_OBJECT": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;
      if (get(draft, `${path}.${key}`)[selector] === data[selector]) {
        set(get(draft, path), key, {});
      }
      break;
    }
    case "UPDATE_DIVISION_INFO": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;

      data.forEach((item) => {
        const index = get(draft, `${path}.${key}`).findIndex(
          (x) => x[selector] === item[selector]
        );
        if (index >= 0) {
          set(get(draft, `${path}.${key}`), index, {
            ...get(draft, `${path}.${key}`)[index],
            ...item,
          });
        } else {
          get(draft, `${path}.${key}`).push({
            ...item,
            amount: 0,
            included: true,
            predecease: "",
          });
        }
      });
    }
    case "UPDATE_PET_INFO": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;

      data.forEach((item) => {
        const index = get(draft, `${path}.${key}`).findIndex(
          (x) => x[selector] === item[selector]
        );
        if (index >= 0) {
          set(get(draft, `${path}.${key}`), index, {
            ...get(draft, `${path}.${key}`)[index],
            ...item,
          });
        } else {
          get(draft, `${path}.${key}`).push({
            ...item,
            guardian_first_name: "",
            guardian_last_name: "",
          });
        }
      });
    }
    case "UPDATE_EXECUTOR_INFO": {
      const {
        path,
        key,
        value: { selector, data },
      } = payload;

      data.forEach((item) => {
        if (get(draft, `${path}.${key}`)[selector] === item[selector]) {
          set(get(draft, path), key, {
            ...get(draft, `${path}.${key}`),
            ...item,
          });
        }
      });
    }
  }
}, initialState);

export default reducer;
