import Cookies from "js-cookie";

export enum AuthType {
  COOKIE,
  AUTH0,
}

export default class Auth {
  private _type: AuthType;
  private _auth: unknown;
  private _isAuthed = false;
  private _email: string;
  private _firstName: string;
  private _lastName: string;
  private _id: string;

  constructor() {
    //populate with data if there is data stored in the cookies.
    this._email = Cookies.get("bare_auth_email");
    this._firstName = Cookies.get("bare_auth_first_name");
    this._isAuthed = !!Cookies.get("bare_auth_authenticated");
  }

  get user(): any {
    return {
      given_name: this._firstName,
      email: this._email,
      first_name: this._firstName,
      family_name: this._lastName,
    };
  }

  refreshUserData() {
    this._email = Cookies.get("bare_auth_email");
    this._firstName = Cookies.get("bare_auth_first_name");
    this._isAuthed = !!Cookies.get("bare_auth_authenticated");
  }

  static getInstance(): Auth {
    if (typeof _instance === "undefined") {
      _instance = new Auth();
    }

    return _instance;
  }

  id(id: string): Auth {
    this.setId(id);
    return this;
  }

  firstName(firstName: string): Auth {
    this.setFirstName(firstName);
    return this;
  }

  lastName(lastName: string): Auth {
    this.setLastName(lastName);
    return this;
  }

  email(email: string): Auth {
    this.setEmail(email);
    return this;
  }

  auth(auth: unknown): Auth {
    this._auth = auth;
    return this;
  }

  authed(authed: boolean): Auth {
    this._isAuthed = authed;
    return this;
  }

  type(type: AuthType): Auth {
    this._type = type;
    return this;
  }

  setId(id: string): void {
    this._id = id;
  }

  getId(): string {
    return this._id;
  }

  setLastName(lastName: string): void {
    this._lastName = lastName;
  }

  getLastName(): string {
    return this._lastName;
  }

  setFirstName(firstName: string): void {
    this._firstName = firstName;
  }

  getFirstName(): string {
    return this._firstName;
  }

  setEmail(email: string): void {
    this._email = email;
  }

  getEmail(): string {
    return this._email;
  }

  isAuthed(): boolean {
    return this._isAuthed;
  }

  setIsAuthed(isAuthed: boolean): void {
    this._isAuthed = isAuthed;
  }

  getType(): AuthType {
    return this._type;
  }

  setType(type: AuthType): void {
    this._type = type;
  }

  getAuth(): unknown {
    return this._auth;
  }

  setAuth(auth: unknown): void {
    this._auth = auth;
  }
}

let _instance = new Auth();

// export default _instance as Auth;
