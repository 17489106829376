export default (): string => {
  const csrfElement = document.querySelector(
    "[name=csrf-token]"
  ) as HTMLMetaElement;

  let csrfToken;

  if (csrfElement) {
    csrfToken = csrfElement.content;
  }

  return csrfToken;
};
