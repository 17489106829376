import { getCookie } from "../helpers/getCookie";
import useAuthWrapped from "./useAuthWrapped";
import useIsAdmin from "./useIsAdmin";

/**
 * React hook to check if there is a currently authenticated user.
 * @returns true if there is an auth0 user else false
 */
const useIsAuthed = (): boolean[] => {
  const auth: any = useAuthWrapped();
  const cookieAuth = getCookie("bare_auth_authenticated") === "true";
  const returnData = [auth._isAuthed, useIsAdmin(), false];

  if (cookieAuth) {
    returnData[0] = true;
    returnData[2] = true;
  }

  return returnData;
};

export default useIsAuthed;
