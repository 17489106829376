import { v4 as uuid } from "uuid";

export const initialState = {
  id: "",
  type: "",
  loading: false,
  navigation: [],
  schema_version: "",
  validated: "",
  answers: {
    get_started: {
      __meta: {
        valid: "",
        touched: "",
      },
      read_eula: "",
      first_name: "",
      email: "",
      aus_resident: "",
      medical_conditions: "",
      divorce: "",
      dependents: "",
      business_owner: "",
      overseas_assets: "",
      joint_assets: "",
      prev_children: "",
      excl_beneficiary: "",
      superannuation: "",
      estate_planning: "",
      extra_asset: "",
    },
    your_details: {
      __meta: {
        valid: "",
        touched: "",
      },
      first_name: "",
      middle_name: "",
      last_name: "",
      date_of_birth: "",
      have_nickname: "",
      nickname: "",
      address: {},
      phone: "",
    },
    your_relationships: {
      __meta: {
        valid: "",
        touched: "",
      },
      have_spouse: "",
      spouse_status: "",
      spouse_details: {
        id: uuid(),
        first_name: "",
        middle_name: "",
        last_name: "",
      },
      have_children: "",
      list_children: [],
      have_pets: "",
      list_pets: [],
    },
    guardians: {
      __meta: {
        valid: "",
        touched: "",
      },
      have_guardian: "",
      guardian: {
        id: uuid(),
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
      },
      have_backup_guardian: "",
      backup_guardian: {
        id: uuid(),
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
      },
      have_pet_guardians: "",
      pet_guardians: [],
    },
    gifts: {
      __meta: {
        valid: "",
        touched: "",
      },
      have_gifts: "",
      list_gifts: [],
    },
    dividing_your_estate: {
      __meta: {
        valid: "",
        touched: "",
      },
      have_equal_division: "",
      have_spouse_predecease_equal_division: "",
      have_spouse_inherit_all: "",
      have_charities: "",
      have_owner_charity: "",
      list_beneficiaries: [],
      have_exclusions: "",
      exclusion_details: "",
      data_sharing_consent: "",
    },
    executors: {
      __meta: {
        valid: "",
        touched: "",
      },
      executor_type: "",
      executor_one: "",
      executor_two: "",
      other_executors: [],
      bare_executor_agreement: "",
      have_backup_executor: "",
      backup_executor: "",
    },
    funeral_wishes: {
      __meta: {
        valid: "",
        touched: "",
      },
      service_type: "",
      have_prepaid: "",
      service_details: "",
      provider_preference: "",
    },
    final_wishes: {
      __meta: {
        valid: "",
        touched: "",
      },
      additional_wishes: "",
    },
  },
  created_at: "",
};
