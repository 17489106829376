import * as React from "react";
import { FC } from "react";
import SVG from "react-inlinesvg";

export const BareLogo: FC = ({ ...rest }) => (
  <span>
    <SVG
      {...rest}
      fill="currentColor"
      className="bare-logo"
      src={"../../assets/bare/brand-mark.svg"}
    />
  </span>
);
