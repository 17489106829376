import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { FC } from "react";
import { useHistory } from "react-router";
import { LoadingIndicator } from "../components";
import { ErrorModal } from "../components/ErrorModal";

export const AuthWrapper: FC = (props): JSX.Element => {
  const auth0 = useAuth0();
  const history = useHistory();

  const errorPush = () => {
    history.push("/");
  };

  // Check if auth is loading
  if (auth0.isLoading) {
    return (
      <>
        <LoadingIndicator />;
      </>
    );
  }

  // Check if auth error
  if (auth0.error) {
    return (
      <>
        <ErrorModal
          title="Error"
          content={auth0.error.message}
          linkBack={errorPush}
        />
      </>
    );
  }

  return <>{props.children}</>;
};
