/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import produce from "immer";

const initialState = {
  isAuthed: false,
  authType: "",
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case "SET_AUTH_STATE":
      draft.isAuthed = payload;
  }
}, initialState);

export default reducer;
